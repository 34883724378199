import {
  Community,
  CommunityPostResponse,
  PinnedPostRequest,
  CommunityPaginated,
  CommunityInfo,
} from '@solin-fitness/types';
import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from 'react-query';
import { api, Methods, serverApi } from '../services/api';
import { useRouter } from 'next/router';
import { IncomingMessage } from 'http';
import { number } from 'yup';

/**
 * API calls
 */

const fetchProgramPostById = async (
  postId: number | string,
  entityId: number | string,
) =>
  await api<CommunityPostResponse>(
    Methods.get,
    `/programs/${entityId}/post/${postId}`,
  );

const fetchPagePostById = async (
  postId: number | string,
  entityId: number | string,
) =>
  await api<CommunityPostResponse>(
    Methods.get,
    `/page/${entityId}/post/${postId}`,
  );

export const fetchCommunityPostsProgram = async (programId: number) =>
  await api<Community>(Methods.get, `/programs/${programId}/post`);

export const fetchProgramPosts = async ({
  cursor = 0,
  id,
}: {
  cursor?: number;
  id: number;
}) =>
  await api<CommunityPaginated>(
    Methods.get,
    `/programs/${id}/post/paginated?cursor=${cursor}&take=20`,
  );

export const fetchPagePosts = async ({
  cursor = 0,
  id,
}: {
  cursor?: number;
  id: number;
}) =>
  await api<CommunityPaginated>(
    Methods.get,
    `/page/${id}/post/paginated?cursor=${cursor}&take=20`,
  );

const fetchProgramPinnedPost = async (programId: number | string) =>
  await api<CommunityInfo>(
    Methods.get,
    `/programs/${programId}/post/pinned-post`,
  );

const fetchPagePinnedPost = async (pageId: number | string) =>
  await api<CommunityInfo>(Methods.get, `/page/${pageId}/post/pinned-post`);

const pinPagePost = async (
  entityId: number | string,
  data: PinnedPostRequest,
) => api(Methods.post, `/page/${entityId}/post/pin`, data);

const pinProgramPost = async (
  entityId: number | string,
  data: PinnedPostRequest,
) => api(Methods.post, `/programs/${entityId}/post/pin`, data);

/**
 * Query Keys
 */

export const communityKeys = {
  all: ['community'] as const,
  pageAll: (id: number | string) =>
    [...communityKeys.all, 'page', Number(id)] as const,
  programAll: (id: number | string) =>
    [...communityKeys.all, 'program', Number(id)] as const,
  pageInfo: (id: number | string) =>
    [...communityKeys.pageAll(id), 'info'] as const,
  programInfo: (id: number | string) =>
    [...communityKeys.programAll(id), 'info'] as const,
  byPostId: (id: number | string) =>
    [...communityKeys.all, Number(id)] as const,
  pageDonations: (id: number | string) =>
    [...communityKeys.pageAll(id), 'donations'] as const,
  programDonations: (id: number | string) =>
    [...communityKeys.programAll(id), 'donations'] as const,
};

/**
 * Queries
 */

export const usePostById = (
  postId: number | string,
  entityId: number | string,
) => {
  const router = useRouter();
  const isProgram = router.pathname.includes('program');
  const handler = isProgram ? fetchProgramPostById : fetchPagePostById;

  return useQuery(communityKeys.byPostId(postId), () =>
    handler(postId, entityId),
  );
};

export const useProgramPosts = (id: number | string) => {
  const router = useRouter();
  const isProgram = router.pathname.includes('program');
  const handler = isProgram ? fetchProgramPosts : fetchPagePosts;

  const keys = isProgram
    ? communityKeys.programAll(id)
    : communityKeys.pageAll(id);

  return useInfiniteQuery(
    keys,
    ({ pageParam = 0 }) => handler({ id: Number(id), cursor: pageParam }),
    {
      getNextPageParam: ({ nextCursor }) =>
        nextCursor !== -1 ? nextCursor : undefined,
    },
  );
};

export const useFetchPinnedPost = (id: number | string) => {
  const router = useRouter();
  const isProgram = router.pathname.includes('program');
  const handler = isProgram ? fetchProgramPinnedPost : fetchPagePinnedPost;

  const keys = isProgram
    ? communityKeys.programInfo(id)
    : communityKeys.pageInfo(id);

  return useQuery(keys, () => handler(id));
};

export const usePinPost = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const isProgram = router.pathname.includes('program');

  const handler = isProgram ? pinProgramPost : pinPagePost;

  return useMutation(
    ({
      entityId,
      data,
    }: {
      entityId: number | string;
      data: PinnedPostRequest;
    }) => handler(entityId, data),
    {
      onSuccess: (_, { entityId }) => {
        if (isProgram) {
          queryClient.invalidateQueries(communityKeys.programAll(entityId));
        } else {
          queryClient.invalidateQueries(communityKeys.pageAll(entityId));
        }
      },
    },
  );
};
