import React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import Typography, { TypeVariant } from '../Typography';
import Icon, { IconNames } from '../Icons';
import styled from 'styled-components';
import UnstyledButton from '../UnstyledButton';
import { motion, useIsPresent } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import clsx from 'clsx';

interface Props {
  title?: string;
  subtitle?: string;
  onDismiss: () => void;
  isOpen: boolean;
  children: React.ReactNode | React.ReactNode[];
  hideGap?: boolean;
  maxWidth?: number;
  shouldCloseOnOutsideClick?: boolean;
  maxHeight?: number;
  theme?: 'dark' | 'light';
}

const Modal = ({
  title,
  subtitle,
  onDismiss,
  children,
  isOpen,
  hideGap,
  maxWidth,
  shouldCloseOnOutsideClick = true,
  maxHeight,
  theme = 'light',
}: Props) => {
  const isPresent = useIsPresent();

  const ease = isPresent ? 'easeOut' : 'easeIn';
  const delay = isPresent ? 0.2 : 0;
  const duration = isPresent ? 0.3 : 0.1;

  return (
    <AnimatePresence>
      {isOpen ? (
        <Overlay onDismiss={onDismiss}>
          <Content onClick={shouldCloseOnOutsideClick ? onDismiss : undefined}>
            <Backdrop
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.3 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay, ease }}
            />
            <Container
              hideGap={hideGap}
              maxWidth={maxWidth}
              background={theme}
              initial={{ opacity: 0, y: 100 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 100 }}
              transition={{ duration, delay, ease }}
              onClick={(e) => e.stopPropagation()}
              maxHeight={maxHeight}
            >
              {title ? (
                <div className="w-full flex justify-between items-start">
                  <h4
                    className={clsx(
                      'text-base font-semibold leading-sm sm:text-xl sm:font-medium',
                      theme === 'dark' ? 'text-white' : 'text-black-500',
                    )}
                  >
                    {title}
                  </h4>
                  <UnstyledButton onClick={onDismiss}>
                    <Icon
                      id={IconNames.x}
                      color={
                        theme === 'light'
                          ? 'var(--color-black-700)'
                          : 'var(--color-white)'
                      }
                    />
                  </UnstyledButton>
                </div>
              ) : null}
              {subtitle ? (
                <p
                  className={clsx(
                    'w-full text-sm font-normal leading-sm tracking-sm sm:text-base',
                    theme === 'dark' ? 'text-white' : 'text-black-500',
                  )}
                >
                  {subtitle}
                </p>
              ) : null}
              <Body>{children}</Body>
            </Container>
          </Content>
        </Overlay>
      ) : null}
    </AnimatePresence>
  );
};

export default Modal;

const Overlay = styled(DialogOverlay)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* this solution pains me but i don't know full consequences of removing the z-index from the header */
  /* which is what this is to combat against */
  z-index: 2;
`;

const Content = styled(DialogContent)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Backdrop = styled(motion.div)`
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: black;
`;

const Body = styled.div`
  overflow: auto;
  width: 100%;
  padding-bottom: 12px;

  scrollbar-gutter: stable;

  &::-webkit-scrollbar {
    display: block;
    background-color: var(--color-off-white-500);
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 999px;
    background-color: hsla(0, 0%, 0%, 0.1);
    border: 1px solid var(--color-off-white-500);
  }
`;

const Container = styled(motion.div)<{
  hideGap?: boolean;
  maxWidth?: number;
  maxHeight?: number;
  background?: 'light' | 'dark';
}>`
  max-height: ${(p) => (p.maxHeight ? `${p.maxHeight}px` : '85vh')};
  width: clamp(
    350px,
    50vw,
    ${(p) => (p.maxWidth ? `${p.maxWidth}px` : '775px')}
  );
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background: ${(p) =>
    p.background === 'light'
      ? 'var(--color-off-white-500)'
      : 'var(--color-black-700-90)'};
  padding: 26px;
  padding-bottom: 0;
  gap: ${(p) => (p.hideGap ? 0 : '18px')};
  box-shadow: var(--shadow-layered-medium);
`;
